<template>
  <div id='brand'>
    <div class='banner_1'>
      <div class='swiper_content'>
        <!--        <div class="swiper-container">-->
<!--        <swiper :options='swiperOptions1'>-->
<!--          <swiper-slide v-for='(item,index) in logoList' :key='index'>-->
        <div v-if='logoList.length > 0'>
          <a :href='logoList[0].link_url && logoList[0].link_url.indexOf("http") >= 0  ? logoList[0].link_url : null' target='_blank'><img :src='logoList[0].img'/></a>
        </div>
<!--          </swiper-slide>-->
          <!-- 如果需要分页器 slot插槽 会自动往里面渲染分页器-->
          <!--                      <div class="swiper-pagination" slot="pagination"></div>-->
          <!-- 如果需要导航按钮 -->
          <!--          <div class='swiper-button-prev prev1' slot='button-prev'></div>-->
          <!--          <div class='swiper-button-next next1' slot='button-next'></div>-->
<!--        </swiper>-->
        <!--      </div>-->
      </div>
    </div>
<!--    <img src='../assets/brand/brandBg.png' alt='' class='banner-img'>-->
    <div class='banner_introduction'>
      <div class='box_introduction'>
        <div class='box_introduction_title'>
          <div class='introduction_title mobile-s'>{{ $t('Brand.BrandService') }}</div>
<!--          <img src='../assets/brand/brandService.png' />-->
        </div>
        <div class='introduction_content'>
          <div class='paragraphs'>
            {{ contentText }}
          </div>
        </div>
<!--        <div class='know_us' @click='gotoHxxd'>-->
<!--          <el-button class='know_more'>了解更多>>></el-button>-->
<!--        </div>-->
      </div>
      <div class='bg_pic'>
        <img src='../assets/brand/eartBg.png' />
      </div>
    </div>
    <!--    宅机弟自孵化账号-->
    <div class='head-info-item' style='padding-top: 0px'>
<!--      <div class='rectangle-right lefts'>-->
<!--        <img src='../assets/rectangle.png' alt=''>-->
<!--      </div>-->
<!--      <div class='header-anchor'>-->
        <div class='brand_introduction_title'>
          <div class='introduction_title'>{{ $t('Brand.cooperationCases') }}</div>
<!--          <img src='../assets/brand/cooperationCases.png' />-->
        </div>
        <div class='header-anchor header-s'>
          <div class='live_swiper'>
            <swiper :options='swiperOptions2' :auto-update="true" ref="mySwiper">
              <swiper-slide v-for='(item,index) in list' :key='index'>
                <a :href='item.link_url&&item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank' style='width: 100%'><img :src=item.img /></a>
              </swiper-slide>
            </swiper>
            <div class='swiper-button-prev' slot='button-prev' @click='prevChange'></div>
            <div class='swiper-button-next' slot='button-next' @click='nextChange'></div>
          </div>
        </div>
<!--      </div>-->
<!--      <div class='rectangle-rights rights'>-->
<!--        <img src='../assets/rectangle.png' alt=''>-->
<!--      </div>-->
    </div>
    <div class='head-info-item' style='width: 100%'>
<!--      <div class='header-anchor' style='min-height: 450px;overflow: hidden'>-->
      <div class='header-anchor' style='min-height: 250px;width: 100%;background-color: #FAFAFA;padding-top: 49px'>
        <div style='margin: 0px 0 0px 0;text-align: center;'>
          <span>{{ $t('Brand.CooperativeBrands') }}</span>
<!--          <img src='../assets/shop/cooperative.png' alt='' class='images information-img'>-->
        </div>
        <div class='cooperation-brand'>
          <div style='width: 60%; padding-top: 40px;margin-left: 20%;padding-left: 20px'>
<!--          <el-row :gutter="45">-->
<!--            <el-col :xs="4" :sm="4" :md="3" :lg="3" :xl="3"  v-for="(item,index) in brandList" :key="index"><img :src='item.img' :key="index"></el-col>-->
<!--          </el-row>-->
           <img  v-for="(item,index) in brandList" :key="index" :src='item.img'/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import homeBanner from '../components/homeBanner'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import {index_List,Logo, brandCase ,brandCooperation} from '@/api/banner'
export default {
  name: 'Brand',
  components:{
    // homeBanner,
    Swiper,
    SwiperSlide
  },
  data(){
    return{
      contentText:'',
      newsList: [
        {
          'img': 'https://img0.baidu.com/it/u=617215503,188424665&fm=253&fmt=auto&app=138&f=JPG?w=120&h=120'
        },
        {
          'img': 'https://img2.baidu.com/it/u=878588444,1785929995&fm=253&fmt=auto&app=138&f=JPG?w=120&h=120'
        },
        {
          'img': 'https://img2.baidu.com/it/u=4276295978,1564416943&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img0.baidu.com/it/u=503580633,3567414311&fm=253&fmt=auto&app=120&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=417744379,2733026919&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img2.baidu.com/it/u=3793272314,3579832883&fm=253&fmt=auto&app=138&f=PNG?w=120&h=120'
        },
        {
          'img': 'https://img0.baidu.com/it/u=365019288,3680240542&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img2.baidu.com/it/u=2878383006,2396702772&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=4110101154,77755075&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img0.baidu.com/it/u=2413108766,1138688916&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=2246582671,1076022454&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=2439673203,2602330429&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=4087371483,2459403015&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img0.baidu.com/it/u=4004226857,4015547609&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img0.baidu.com/it/u=728270901,3143689237&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=166996070,3813994298&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=1229670147,54142161&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=3329779680,1730727527&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img2.baidu.com/it/u=95201979,4265305100&fm=253&fmt=auto&app=138&f=PNG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=2667643370,4182542990&fm=253&fmt=auto&app=138&f=GIF?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=721165360,1838369749&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img1.baidu.com/it/u=3024818213,924427534&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img2.baidu.com/it/u=2279560675,1435387031&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },
        {
          'img': 'https://img2.baidu.com/it/u=1775013149,858928440&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img0.baidu.com/it/u=2525444497,3926769801&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img1.baidu.com/it/u=1622699731,2387912926&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img1.baidu.com/it/u=2603763568,553613880&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img0.baidu.com/it/u=897610780,2733025773&fm=253&fmt=auto&app=138&f=PNG?w=120&h=120'
        },   {
          'img': 'https://img0.baidu.com/it/u=1671708499,2457454968&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img0.baidu.com/it/u=2966861195,1513133659&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img0.baidu.com/it/u=3619946645,769941788&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img1.baidu.com/it/u=2568346834,347660496&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img1.baidu.com/it/u=2725804151,3072200411&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        },   {
          'img': 'https://img1.baidu.com/it/u=596175957,2171475362&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
        }
      ],
      logoList:[],
      swiperOptions1: {
        // 自动播放
        autoplay: {
          delay: 3000,
        },
        // 环路播放
        loop: true,
        // 切换效果
        effect: 'slide',
        cubeEffect: {
          slideShadows: true,
          shadow: true,
          shadowOffset: 100,
          shadowScale: 0.6
        },
        // 前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next.next1',
          prevEl: '.swiper-button-prev.prev1',
        }
      },
      list: [],
      swiperOptions2: {
        // 自动播放
        autoplay: true,
        // 环路播放
        loop: true,
        // 切换效果
        effect: 'slide',
        slidesPerView:4,   //页面分组显示，这里显示为3组
        spaceBetween:28,   //slide间隙
        cubeEffect: {
          slideShadows: true,
          shadow: true,
          shadowOffset: 100,
          shadowScale: 0.6
        },
        // 前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      brandList:[]
    }
  },
  mounted() {
    this.get_index_List()
    this.get_Logo()
    this.get_brandCase()
    this.get_brand_cooperation()
    let  language=localStorage.getItem('locale')
    // var mySwiper2 = new Swiper ('.swiper2', this.swiper2)
  },
  methods: {
    get_index_List:function(){
      index_List().then(resp=>{
        if(resp.status==200){
          console.log('//////////////////',resp.data)
          this.$set(this, 'contentText', resp.data.brand_desc ? resp.data.brand_desc :'')
        }
      }).catch(error=>{

      })
    },
    get_Logo: function() {
      Logo(6).then(resp => {
        if (resp.status == 200) {
          this.$set(this, 'logoList', resp.data)
        }
      }).catch(error => {

      })
    },
    gotoHxxd:function(){
      this.$router.push('aboutUs')
    },
    get_brandCase:function(){
      brandCase({type:1}).then(resp=>{
        if(resp.status==200){
          if(resp.data.length>0){
          this.$set(this,'list',resp.data)
          }else {
            this.$set(this,'list',[])
          }
        }else {
          this.$message({
            type:'info',
            message:resp.msg
          })
        }
      }).catch(error=>{

      })
    },
    get_brand_cooperation:function (){
      brandCooperation().then(resp=>{
        if(resp.status==200){
          this.$set(this,'brandList',resp.data)
        }else {
          this.$set(this,'brandList',[])
          this.$message({
            type:'info',
            message:resp.msg
          })
        }
      }).catch(error=>{

      })
    },
    prevChange () {
      this.$refs.mySwiper.$swiper.slidePrev()
    },
    nextChange () {
      this.$refs.mySwiper.$swiper.slideNext()
    }
  }
}
</script>

<style scoped lang='scss'>
.head-info-item{
  width: 100%;
  //display: flex;
  position: relative;
  padding: 30px 0;
  .header-anchor{
    width: 60%;
    margin: auto;
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333333;
    position: relative;
    line-height: 30px;
    .images{
      width: 261px;
      position: absolute;
      left: 50%;
    }
    .anchor-content-info{
      background: rgba(213, 71, 36, 0.1);
      box-shadow: 0px 8px 6px 0px rgba(153, 153, 153, 0.1);
      border-radius: 40px;
      height: 428px;
      width: 88%;
      overflow: hidden;
      margin: auto;
    }
    .hot-anchor{
      width: 90%;
      margin: auto;
      //height: 356px;
      overflow: hidden;
      .avatar2{
        width: 270px;
        height: 100%;
        background: rgba(213, 71, 36, 0.1);
        border-radius: 10px;
      }
      .nickname-s{
        position: absolute;
        bottom: 10px;
        left: 20px;
        color: #fff;
        line-height: 25px;
        font-size: 18px;
        font-family: SourceHanSansSC;
        font-weight: 500;
        span{
          font-size: 14px;
        }
      }
    }
    .entertainment{
      width: 70%;
      margin: auto;
      height: 434px;
      overflow: hidden;
      margin-bottom: 60px;
    }
    .popular-img{
      width: 341px;
    }
    .address-word{
      width: 181px;
    }
  }
  .header-s{
    margin-top: 67px
  }
  .rectangle-position{
    position: absolute;
    bottom: 0;
    left: 60px;
    img{
      width: 104px;
      height: 73px;
    }
  }
  .lefts{
    left: 260px;
    bottom: 90px;
  }
  .rectangle-right{
    position: absolute;
    top: 80px;
    left: 100px;
    img{
      width: 106px;
      height: 73px;
    }
  }
  .rectangle-rights{
    position: absolute;
    top: 350px;
  }
  .rights{
    bottom:50px!important;
    right: 160px;
  }
  .left-s{
    position: absolute;
    left: 0;

    img{
      width: 275px;
      height: 547px;
    }
  }
  .swiper-slide{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #666666;
  }
  .anchor-list{
    padding: 30px 0 0 70px;
  }
  .anchor-list div:nth-child(1){
    font-size: 20px;
    color: #333333;
    line-height: 50px;
  }
  .anchor-list div:nth-child(3){
    margin: 50px 0 150px 0;
  }
  .anchor-list span:nth-child(4){
    background: #D54724;
    border-radius: 23px;
    padding: 8px 20px;
    color: #fff;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
  }
  .anchor-avatar{
    width: 428px;
    height: 100%;
    border-radius: 40px;
  }
  .anchor-avatar2{
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
.swiper2 {
  width: 100%;
  height: 356px;
  --swiper-theme-color: rgba(232, 75, 36, 1);
  --swiper-navigation-size: 40px;/* 设置按钮大小 */
}
.swiper-button-left,.swiper-button-right{
  color: #D54724;
  background-color: rgba(213, 71, 36, 0.1);
  border-radius: 50%;
  width: 38px;
}
.swiper-button-prev:after,.swiper-button-next:after{
  font-size: 28px !important;
  //font-weight: bold;
}
.banner_1 {
  width: 100%;
  height: 700px;

  .swiper_content {
    width: 100%;
    height: 700px;

    .swiper-container {
      width: 100%;
      height: 700px;

      .swiper-wrapper {
        width: 100%;
        height: 700px;

        .swiper-slide {
          width: 100%;
          height: 700px;
          img {
            width: 100%;
            height: 700px;
          }
        }
      }
    }
    img {
      width: 100%;
      height: 700px;
    }
  }
}
.banner_introduction {
  box-sizing: border-box;
  margin-top: 90px;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  position: relative;

  .box_introduction {
    width: 1200px;
    //height: 490px;
    /*background-color: rgba(213, 71, 36, 0.1);*/
    border-radius: 40px;
    box-sizing: border-box;
    padding: 0px 40px 60px 40px;

    .box_introduction_title {
      margin-top: 50px;
      width: 100%;
      position: relative;
      text-align: center;

      .introduction_title {
        /*width: 120px;*/
        height: 25px;
        font-size: 26px;
        font-family: SourceHanSansCNVF;
        font-weight: 400;
        color: #333333;
        line-height: 50px;
        /*margin-left: calc(50% - 60px);*/
      }

      img {
        position: absolute;
        width: 390px;
        height: 45px;
        margin-left: calc(50% - 5px);
        top: 5px;
      }
    }

    .introduction_content {
      margin-top: 60px;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #4D4D4D;
      line-height: 28px;

      .paragraphs {
        text-indent: 32px;
        font-size: 16px;
        font-family: Source Han Sans CN VF;
        font-weight: 400;
        color: #4D4D4D;
        line-height: 38px;
        text-align: justify;
      }
    }

  }
  .bg_pic{
    width: 100%;
    height: 460px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.know_us {
  text-align: center;

  .know_more {
    background-color: #D54724;
    color: #fff;
    cursor: pointer;
  }
}

.el-button {
  border: none;
  outline: none;
  border-radius: 50px;
}

.el-button:hover {
  background-color: rgba(213, 71, 36, 0.3) !important;
  color: #fff !important;
}
.brand_introduction_title {
  margin-top: 90px;
  width: 100%;
  font-size: 26px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #333333;
  line-height: 50px;
  text-align: center;
  /*position: relative;*/

  .introduction_title {
    font-size: 26px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
    line-height: 50px;
  }

  img {
    position: absolute;
    width: 535px;
    height: 45px;
    margin-left: calc(50% - 1px);
    top: 5px;
  }
}
.cooperation-brand{
  width: 100%!important;
  /*float: left;*/
  /*padding-left: 40px;*/
  /*justify-content: space-between;*/
  /*flex-flow: row wrap;*/

  img{
    width: 98px;
    height: 98px;
    box-shadow: 0 2px 2px 0 rgba(243, 228, 225, 1);
    border-radius: 20px;
    transition: all 0.6s;
    margin-right: 44px;
    margin-bottom: 44px;
  }
  img:hover{
    transform: scale(1.15);
    box-shadow: 0 2px 5px 0 rgba(213, 71, 36, 0.5);

  }
}

.anchor_swiper{
  width: 80%;
}
.live_swiper {
  width: 1080px;
  height: 482px;
  //border: 1px solid #D54724;
  //border-radius: 40px;

  .swiper-container {
    width: 1080px;
    height: 482px;
    //border-radius: 40px;

    .swiper-wrapper {
      width: 1080px;
      height: 482px;
      //border-radius: 40px;

      .swiper-slide {
        border-radius: 40px;
        width: 249px;
        height: 482px;
        box-sizing: border-box;
        //border: 2px solid #D54724;
        img {
          width: 249px;
          height: 482px;
          border-radius: 40px;
        }
      }
    }

  }
}
.swiper-button-prev{
  left: -55px !important;
  width: 46px;
  height: 46px;
  text-align: center;
  line-height: 46px;
  border-radius: 50%;
  color: #D54724;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px 0px rgba(21,25,37,0.1700);
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev{
  width: 46px;
  height: 46px;
  text-align: center;
  line-height: 46px;
  border-radius: 50%;
  color: #D54724;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px 0px rgba(21,25,37,0.1700);
}
.swiper-button-next:hover, .swiper-button-prev:hover{
  background-color: #DB6143;
  color: #fff;
}
.el-button {
  border: none;
  outline: none;
  border-radius: 50px;
}

.el-button:hover {
  background-color: rgba(213, 71, 36, 0.3) !important;
  color: #fff !important;
}

</style>
